/* Голубий фон для календаря */
.fc {
    background-color: lightblue;
    background-image: url('https://api.rotary.org.ua/uploads/clubsitecreator/D2232/Logo/1707318206588-895329449.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  /* Стилі для кнопок календаря */
  .fc .fc-button {
    background-color: blue;
    border-color: blue;
    color: white;
  }
  
  .fc .fc-button:hover {
    background-color: darkblue;
    border-color: darkblue;
  }
  
  /* Стилі для стрілок */
  .fc .fc-prev-button, .fc .fc-next-button {
    background-color: blue;
    border-color: blue;
    color: white;
  }
  
  .fc .fc-prev-button:hover, .fc .fc-next-button:hover {
    background-color: darkblue;
    border-color: darkblue;
  }
  
  /* Стилі для активної кнопки */
  .fc .fc-button.fc-button-primary.fc-button-active {
    background-color: orange !important;
    border-color: orange !important;
    color: white !important;
  }
  